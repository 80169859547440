<template>
  <div class="container">
    <div class="formBox">
      <p>欢迎登录</p>
      <p>
        <span>没有账号？</span>
        <span @click="() => this.$router.push('/register')">立即注册</span>
      </p>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="login">
          <el-input
            placeholder="用户名"
            prefix-icon="el-icon-user"
            v-model.trim="form.login"
            onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="密码"
            prefix-icon="el-icon-lock"
            v-model.trim="form.password"
            type="password"
          />
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            placeholder="验证码"
            prefix-icon="el-icon-circle-check"
            v-model.trim="form.code"
            onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            @keydown.enter.native="handleLogin"
          >
            <img
              :src="verifyUrl"
              alt="verifyCode"
              slot="suffix"
              class="verifyImg"
              @click="this.changeVerifyCode"
            />
          </el-input>
        </el-form-item>
      </el-form>
      <el-button
        :loading="loading"
        class="logIn"
        type="primary"
        @click="handleLogin"
      >
        登录
      </el-button>
      <div class="forgetPw" @click="handleForget">
        <span>忘记密码</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getVerifyCode, getLogin } from './api'
import { mapMutations } from 'vuex'

export default {
  mounted() {
    this._getVerifyCode()
  },
  data() {
    return {
      form: {
        login: '',
        password: '',
        code: '',
      },
      rules: {
        login: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [
          { required: true, message: '请输入图片验证码', trigger: 'blur' },
        ],
      },
      loading: false,
      verifyUrl: '',
    }
  },
  methods: {
    ...mapMutations(['setMenus', 'setToken', 'setUsername']),
    async _getVerifyCode() {
      try {
        this.verifyUrl = await getVerifyCode()
      } catch (err) {
        console.error(err)
      }
    },
    changeVerifyCode() {
      this._getVerifyCode()
    },
    handleForget() {
      this.$router.push('/forget-password')
    },
    async handleLogin() {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (valid) {
          const params = { ...this.form }
          const { menu, token } = await getLogin(params)
          this.$message.success('登录成功')
          this.setMenus(menu) // 存menu
          this.setToken(token) // 存token
          this.setUsername(params.login)
          await this.$router.push('/')
        }
      } catch (err) {
        await this._getVerifyCode()
        console.error(err)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
$normalFont: 14px;
$largeFont: 16px;
$smallFont: 12px;
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  background: url('./image/bg.png') no-repeat center;
  background-size: cover;

  .formBox {
    width: 468px;
    height: 568px;
    background: #ffffff;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 0 60px 46px 60px;
    .verifyImg {
      display: inline-block;
      width: 104px;
      height: 35px;
      position: relative;
      top: 3px;
      cursor: pointer;
      //border: 1px solid #cccccc;
      border-radius: 8px;
    }
    .el-form {
      margin-bottom: 32px;
    }
    .logIn {
      width: 100%;
      background: #2c68ff;
      border-radius: 8px;
      color: #fff;
      font-size: 24px;
      margin-bottom: 18px;
    }
    .forgetPw {
      width: 100%;
      display: flex;
      justify-content: center;
      span {
        font-size: 16px;
        color: #2c68ff;
        cursor: pointer;
      }
    }
    & > p:first-child {
      width: 100%;
      height: 130px;
      text-align: center;
      line-height: 130px;
      font-size: 24px;
      font-weight: 500;
      color: #2c68ff;
    }
    & > p:nth-child(2) {
      text-align: right;
      margin-bottom: 8px;
      span {
        font-size: 16px;
      }
      & > span:first-child {
        color: #cccccc;
      }
      & > span:nth-child(2) {
        color: #2c68ff;
        cursor: pointer;
      }
    }
    .el-form-item {
      margin-bottom: 32px;
    }
    .el-input__inner {
      height: 56px;
    }
  }
}
</style>
